<template>
  <v-container class="pa-0" style="background-color:black;height:100%;width:100%;max-width:100%;">
    <v-app-bar app color="primary" elevation="0" dark dense>
      <div class="d-flex align-center">
        <v-icon class="mr-2" large>mdi-video-wireless</v-icon>
        <div class="text-h6 font-weight-bold">EdgeEye 모아보기</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="dlgDevList = true"><v-icon>mdi-cog</v-icon></v-btn>
    </v-app-bar>
    <v-row class="pt-1 pl-1" no-gutters>
      <template v-for="device in edgeEyeDevices">
        <v-col class="pa-1 view-border" v-if="watchingDevs.includes(device.devId)"  :key="device.node_id" :cols="calulatedCols">
          <v-card elevation="0" tile style="background-color:black;">
            <v-row no-gutters>
              <v-col cols="7">
                <div class="view-header-text">{{device.groupName}} - {{ device.devDesc }}</div>
              </v-col>
              <v-col cols="5">
                <div class="view-header-data-text" style="text-align:right">{{ device.devPairData != undefined ? device.devPairData : '' }}</div>
              </v-col >
            </v-row>
            <img class="row-pointer width-max-full" :src="updateSrc(device.devId)"
              @click="openPicture(device.groupId, device.groupName, device.devId, device.devDesc)"
              @error="imageError">
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="dlgPic" width="unset" :retain-focus="false">
      <!-- <v-sheet class="px-2 pb-2">
        <v-list-item>
            <v-list-item-title>{{selectedDevDesc}}</v-list-item-title>
            <v-list-item-icon>
              <v-btn icon @click="dlgPic = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-list-item-icon>
        </v-list-item>
        <img :src='"/api/edge-eye/"+selectedDevId' @error="imageError" />
      </v-sheet> -->
      <EdgeEyeViewer :isAdmin="true" :devId="selectedDevId" :devDesc="selectedDevDesc" :groupId="selectedGroupId" 
        v-on:close="dlgPic = false"></EdgeEyeViewer>
      </v-dialog>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64">Loading</v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dlgDevList" width="700" :retain-focus="false">
      <v-sheet class="px-3 py-2" style="height:700px;">
        <v-list>
          <v-list-item two-line>
            <v-list-item-content style="border-left: 6px solid #2c3e50;padding-left:10px;margin-bottom:5px;">
              <v-list-item-title>EdgeEye 장치 목록 설정</v-list-item-title>
              <v-list-item-subtitle>화면에 표시할 장치를 선택해주세요</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
        </v-list>
        <v-checkbox v-model="toggleCheck" class="ml-4 mb-2" label="전체 선택" hide-details></v-checkbox>
        <v-list class="ml-3" style="height:480px;overflow-y:scroll; background-color:#EFEFEF;">
          <v-list-item v-for="dev in edgeEyeDevices" :key="dev.devId" style="min-height: 0 !important;">
            <v-checkbox v-model="watchingDevs" :value="dev.devId" 
              :label="getDevLabel(dev)" hide-details></v-checkbox>
          </v-list-item>
        </v-list>
        <v-list class="mt-6" >
          <v-list-item>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="3" class="pr-3">
                <v-btn class="" Depressed block @click="dlgDevList = false" elevation="0">닫기</v-btn>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import EdgeEyeViewer from './Common/EdgeEyeViewer.vue';

async function wait(ms) {
  return new Promise( (resolve) => {setTimeout(resolve, ms)});
}

export default {
  name: 'EdgeEyeGrid',
  components: {
    EdgeEyeViewer
  },
  watch: {
    async watchingDevs(newVal, oldVal) {
      await axios({ method: 'put', url: '/api/session',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          data: {watchingList: this.watchingDevs}
      });
    },
    toggleCheck(newVal, oldVal) {
      if(newVal) {
        console.log('all check');
        let _watchingDevs = [];
        for(let i=0; i<this.edgeEyeDevices.length; i++) {
          _watchingDevs.push(this.edgeEyeDevices[i].devId);
        }
        this.watchingDevs = _watchingDevs;
      } else {
        console.log("all uncheck");
        this.watchingDevs = [];
      }
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    calulatedCols() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12;
        case 'sm': return 4;
        case 'md': return 3;
        case 'lg': return 2;
        case 'xl': return 2;
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.watchingDevs = (await axios({ method: 'get', url: '/api/session/watching_list',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
    })).data;
    await this.loadDevices();
    this.loading = false;

    setInterval(() => {
      this.loadDevices();
    }, 10000);
  },
  methods: {
    async loadDevices() {
      console.log('loadDevices');
      try {
        let ret = await axios({ method: 'get', url: '/api/groups',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let _edgeEyeDevices = [];
        let _watchingDevs = [];
        let groups = ret.data.groups;
        for(let i=0; i<groups.length; i++) {
          await wait(50);
          let ret = await axios({ method: 'get', url: `/api/devices/${groups[i].groupId}`,
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          });
          let devices = ret.data.devices;
          if(devices != null && devices.length > 0) {
            let _devs = [];
            for(let j=0; j<devices.length; j++) {
              if(devices[j].devType == 'EdgeEye') {
                let _dev = {...devices[j]};
                _dev['groupName'] = groups[i].groupName;
                _dev['groupId'] = groups[i].groupId;
                _devs.push(_dev);
                for(let k=0; k<devices.length; k++) {
                  if(devices[j].devDesc == devices[k].devType) {
                    if(devices[k].devLastData['m^3/hour'] != undefined)
                    _dev.devPairData = `${parseFloat(devices[k].devLastData['m^3/hour']).toFixed(2)} m³/hour`;
                    break;
                  }
                }
                if(this.watchingDevs.length == 0) {
                  _watchingDevs.push(_dev.devId);
                }
              }
            }
            _edgeEyeDevices = _edgeEyeDevices.concat(_devs);
          }
        }
        if(this.watchingDevs.length == 0) {
          this.watchingDevs = _watchingDevs;
          await axios({ method: 'put', url: '/api/session',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
            data: {watchingList: this.watchingDevs}
          });
        }
        this.edgeEyeDevices = _edgeEyeDevices;
      } catch(e) {
        console.log(e);
      }
    },
    updateSrc(devId) {
      return "/api/edge-eye/"+devId+"?key="+new Date().getTime();
    },
    openPicture(groupId, groupName, devId, devDesc) {
      this.selectedDevDesc = `${groupName} - ${devDesc}`;
      this.selectedDevId = devId;
      this.selectedGroupId = groupId;
      this.dlgPic = true;
    },
    imageError: function(e) {
      e.target.src = '/image_not_available-640-480.png';
    },
    getDevLabel(dev) {
      return `[${dev.devId}] ${dev.groupName} - ${dev.devDesc}`;
    },
    async applyWatchingDevs() {
      await axios({ method: 'put', url: '/api/session',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          data: {watchingList: this.watchingDevs}
      });
      this.dlgDevList = false;
    }
  },
  data() {
    return {
      loading: false,
      dlgDevList: false,
      edgeEyeDevices: [],
      toggleCheck: false,
      watchingDevs: [],
      dlgPic: false,
      selectedDevDesc: '',
      selectedDevId: '',
      selectedGroupId: ''
    };
  },
}
</script>

<style>
.row-pointer {
  cursor: pointer;
}
.width-max-full {
  max-width: 100%;
}
.view-header-text {
  font-size: 13px;
  color:white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
}
.view-header-data-text {
  font-size: 13px;
  color:white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
}
.view-border {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: grey;
}
.v-input--selection-controls {
  margin-top: 4px;
  padding-top: 4px;
}
</style>

