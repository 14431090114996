<template>
  <v-card class="mx-auto mb-1" elevation="1" outlined>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{curTitle}}
        </v-list-item-title>
        <v-list-item-subtitle>
          Keep-alive: {{this.keepalive}}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="hasSolarSense">
          <v-chip class="my-1 mr-1" label outlined>
            <v-icon class="mr-1" :color="getDayNightIconColor()" medium>{{ getDayNightIcon() }}</v-icon>
            {{dayNight}}
          </v-chip>
          <v-chip class="my-1 mr-1" label outlined @click="toggleChart">
            <v-icon :color="battPercent < 20 ? 'red' : 'green'" class="mr-1">{{ getBattIcon() }}</v-icon>
            <span></span>{{battPercent}}% 
            <v-icon class="ml-1">{{ isChartShowing ? 'mdi-menu-down' : 'mdi-menu-left' }}</v-icon>
          </v-chip>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="hasSolarSense && isChartShowing">
          <v-chip class="mt-2" small>최근 5일간 충방전 기록</v-chip>
          <v-sheet style="height: 200px;">
            <SimpleChart :key="chartRedrawKey" chartId="battChart" title="배터리 (%)" :yAxisMin=yAxisMin :yAxisMax=yAxisMax
              :borderColor="chartStyle.borderColor" :fillColor="chartStyle.fillColor"
              :dataArr="battData" yUnit="%" />
          </v-sheet>
          <v-sheet style="height: 200px;">
            <SimpleMultiChart :key="chartRedrawKey" chartId="solarChart" title="태양광 충전/방전 전력 (W)" :yAxisMin=yAxisMin :yAxisMax=yAxisMax
              :borderColor="chartStyle.borderColor" :fillColor="chartStyle.fillColor"
              :dataArr="solarData" yUnit="%" />
          </v-sheet>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon v-if="isAdmin && this.$route.query.groupId == undefined" 
          class="" color="primary" @click="openDashboard" medium>mdi-open-in-new</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-list class="transparent" v-for="(sensor, index) in sensors" :key="index">
      <v-list-item style="min-height:40px;">
        <v-list-item-title>
          <v-icon color='blue darken-3' v-if="sensor.devType.startsWith('유량계')">mdi-waves</v-icon>
          <v-icon color='blue' v-if="sensor.devType == '강우량계'">mdi-weather-pouring</v-icon>
          {{sensor.devType}}
        </v-list-item-title>
        <v-list-item-subtitle>
          <div class="d-flex flex-column">
            <div>{{sensor.devData}} {{sensor.devDgree}}</div>
            <div style="font-size:11px;">{{sensor.devDataTs}}</div>
          </div>
        </v-list-item-subtitle>
        <v-list-item-icon v-if="isAdmin" style="margin:0px;">
          <v-menu bottom left min-width="140">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" v-if="sensor.devType.startsWith('유량계')">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item link>
                <v-list-item-content @click="openSettingFlowMeter(sensor.devId, sensor.devDesc)">
                  <v-list-item-title>수식 설정</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item two-line style="padding:10px;margin:0px;" v-if="hasPairEdgeEye(sensor)">
        <v-list-item-content style="padding:0px;margin:0px;">
          <v-list-item-subtitle>
            <img class="row-pointer width-max-full" :src="getEdgeEyeUrl(getPairEdgeEye(sensor))"
              @click="openPicture(getPairEdgeEye(sensor))" @error="imageError" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list class="transparent" v-for="(edgeEye, index) in edgeEyes" :key="index">
      <v-list-item two-line style="padding:10px;margin:0px;" v-if="!hasPairSensor(edgeEye)">
        <v-list-item-content style="padding:0px;margin:0px;">
          <v-list-item-title class="mb-2 ml-2"><v-icon class="mb-2">mdi-video-wireless</v-icon> 
            {{edgeEye.devDesc.length > 0 ? edgeEye.devDesc : "EdgeEye"}}
          </v-list-item-title>
          <v-list-item-subtitle>
            <img class="row-pointer width-max-full" :src="getEdgeEyeUrl(edgeEye)"
              @click="openPicture(edgeEye)" @error="imageError" />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- check type and show setting-->
    <FlowMeterSettingDialog v-if="isAdmin" :dlg="dlgFlowMeterSetting" :groupId="groupId" :groupName="groupName" 
      :devId="selectedDevId" :devDesc="selectedDevDesc" v-on:close="dlgFlowMeterSetting = false"></FlowMeterSettingDialog>

    <!-- <v-list class="transparent" v-if="sensors.length < 1">
      <v-list-item>
        <v-list-item-title>장치가 없음</v-list-item-title>
      </v-list-item>
    </v-list> -->

    <v-dialog v-model="dlgPic" width="unset" :retain-focus="false" >
      <EdgeEyeViewer :isAdmin="isAdmin" :devId="selectedDevId" :devDesc="selectedDevDesc" :groupId="groupId" 
        v-on:close="dlgPic = false"></EdgeEyeViewer>
    </v-dialog>

    <loading :active.sync="loading" :is-full-page="false" loader="bars" />
  </v-card>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Utils from '../../js/utils';
import FlowMeterSettingDialog from '../AdminView/FlowMeterSettingDialog.vue';
import SimpleChart from './SimpleChart.vue';
import SimpleMultiChart from './SimpleMultiChart.vue';
import EdgeEyeViewer from './EdgeEyeViewer.vue';

export default {
  name: 'MultiDataPanel',
  components: {
    FlowMeterSettingDialog,
    Loading,
    SimpleChart,
    SimpleMultiChart,
    EdgeEyeViewer
  },
  props: {
    isAdmin: Boolean,
    groupId: String,
    groupName: String,
    keepalive: String,
    redrawFlag: Boolean
  },
  watch: {
    redrawFlag() {
      this.updatePanel();
    },
    groupId() {
      if(this.groupId != null) {
        this.loading = true;
      }
    }
  },
  mounted() {
  },
  methods: {
    async openDashboard() {
      const route = this.$router.resolve({path: `/dashboard?groupId=${this.groupId}&groupName=${this.groupName}`});
      window.open(route.href, '_blank');
    },
    toggleChart() {
      this.isChartShowing = !this.isChartShowing;
    },
    async updatePanel() {
      console.log('update MultiDataPanel');
      if(this.isAdmin && this.groupId == null) { return; }
    
      let _sensors = [];
      let _edgeEyes = [];
      try {
        this.curTitle = this.groupName;
        let ret = await axios({ method: 'get', url: `/api/devices${this.isAdmin ? '/'+this.groupId : ''}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let latestTs = 0;
        let devices = ret.data.devices;
        this.hasSolarSense = false;
        if(devices != null && devices.length > 0) {
          let rainMeter = null;
          for(let i=0; i<devices.length; i++) {
            if(devices[i].devType == '강우량계') {
              rainMeter = {
                devId: devices[i].devId,
                devType: devices[i].devType,
                devDesc: devices[i].devDesc,
                devDgree: 'mm/h',
                devData: '--',
                devDataTs: ''
              };
              if(devices[i].devLastData != undefined && devices[i].devLastData.pulse != undefined) {
                rainMeter.devData = parseFloat(devices[i].devLastData['mm/hour']).toFixed(2);
                let dataTs = new Date(devices[i].devLastDataTs);
                rainMeter.devDataTs = dataTs.toLocaleString();
                if(latestTs < dataTs.getTime()) {
                  latestTs = dataTs;
                }
              }
            } else if(/^유량계-.*/.test(devices[i].devType)) {
              let _sensor = {
                devId: devices[i].devId, 
                devType: devices[i].devType,
                devDesc: devices[i].devDesc,
                devDgree: 'm³/h',
                devData: '--',
                devDataTs: ''
              };
              if(devices[i].devLastData != undefined && devices[i].devLastData.distance != undefined) {
                _sensor.devData = parseFloat(devices[i].devLastData['m^3/hour']).toFixed(2);
                let dataTs = new Date(devices[i].devLastDataTs);
                _sensor.devDataTs = dataTs.toLocaleString();
                if(latestTs < dataTs.getTime()) {
                  latestTs = dataTs;
                }
              }
              _sensors.push(_sensor);
            } else if(devices[i].devType == 'CSD3') {
              this.battDevId = devices[i].devId;
              this.hasSolarSense = true;
              this.dayNight = devices[i].devLastData['day_night'];
              this.isCharging = this.dayNight == 'Day';
              this.battPercent = devices[i].devLastData['battery_soc_%'];
            } else if(devices[i].devType == 'EdgeEye') {
              let _edgeEye = {
                devId: devices[i].devId,
                devType: devices[i].devType,
                devDesc: devices[i].devDesc,
              };
              _edgeEyes.push(_edgeEye);
            }
          }
          _sensors = Utils.devSort(_sensors);
          if(rainMeter != null) {
            _sensors.unshift(rainMeter)
          }
        }
        this.latestTs = latestTs == 0 ? "--" : latestTs.toLocaleString();
      } catch (error) {
        console.log(error.response);
      }
      this.sensors = _sensors;
      this.edgeEyes = _edgeEyes;
      this.curDate = new Date().toLocaleString();

      await this.fetchBattData(this.battDevId);
      this.loading = false;
    },
    async fetchBattData(nid) {
      let toTime = new Date();
      toTime.setHours(23, 59);
      let fromTime = new Date();
      fromTime.setDate(toTime.getDate() - 5);
      fromTime.setHours(0);
      fromTime.setMinutes(0);

      let from = fromTime.toISOString();
      let to = toTime.toISOString();

      let grpId = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.groupId}`;
      }
      let queryStr = `nid=${nid}&from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/data${grpId}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let data = ret.data.data;
      let battData = [];
      let solarData1 = [];
      let solarData2 = [];
      for(let i=0; i<data.length; i++) {
        battData.push({x: new Date(data[i].timestamp), y: data[i].value['battery_soc_%']});
        solarData1.push({x: new Date(data[i].timestamp), y: data[i].value['load_power_W']});
        solarData2.push({x: new Date(data[i].timestamp), y: data[i].value['pv_array_input_power_W']});
      }
      
      this.battData = battData;
      this.solarData = [
        {tag: '방전전력', data: solarData1, color: 'blue'},
        {tag: '충전전력', data: solarData2, color: 'red'}
      ];
      this.chartRedrawKey = !this.chartRedrawKey;
    },
    openSettingFlowMeter(devId, devDesc) {
      this.selectedDevId = devId;
      this.selectedDevDesc = devDesc;
      this.dlgFlowMeterSetting = true;
    },
    getDayNightIconColor() {
      if(this.dayNight == 'Day') {
        if(this.isCharging) {
          return 'red';
        } else {
          return 'blue';
        }
      } else {
        return `primary`;
      }
    },
    getDayNightIcon() {
      if(this.dayNight == 'Day') {
        if(this.isCharging) {
          return `mdi-white-balance-sunny`;
        } else {
          return `mdi-weather-partly-cloudy`;
        }
      } else {
        return `mdi-weather-night`;
      }
    },
    getBattIcon() {
      if(this.battPercent < 10) {
        if(this.isCharging) {
          return 'mdi-battery-charging-outline';
        } 
        return 'mdi-battery-alert-variant-outline';
      }
      if(this.battPercent == 100 && this.isCharging == false) {
        return `mdi-battery`;
      }
      return `mdi-battery${this.isCharging ? '-charging' : ''}-${Math.floor(this.battPercent/10)*10}`;
    },
    openPicture(dev) {
      if(dev != null) {
        this.selectedDevDesc = dev.devDesc;
        this.selectedDevId = dev.devId;
        this.dlgPic = true;
      }
    },
    getEdgeEyeUrl(dev) {
      return `/api/edge-eye/${dev.devId.toUpperCase()}?key=${new Date().getTime()}`;
    },
    imageError: function(e) {
      e.target.src = '/image_not_available.png';
    },
    hasPairEdgeEye(sensor) {
      for(let i=0; i<this.edgeEyes.length; i++) {
        if(this.edgeEyes[i].devDesc == sensor.devType) {
          return true;
        }
      }
      return false;
    },
    hasPairSensor(edgeEye) {
      for(let i=0; i<this.sensors.length; i++) {
        if(this.sensors[i].devType == edgeEye.devDesc) {
          return true;
        }
      }
      return false;
    },
    getPairEdgeEye(sensor) {
      for(let i=0; i<this.edgeEyes.length; i++) {
        if(this.edgeEyes[i].devDesc == sensor.devType) {
          return this.edgeEyes[i];
        }
      }
      return null;
    }
  },
  data() {
    return {
      loading: false,
      sensors: [],
      edgeEyes: [],
      latestTs: "--",
      curTitle: "--",
      curDate: new Date().toLocaleString(),
      selectedDevId: '',
      selectedDevDesc: '',
      dlgFlowMeterSetting: false,
      hasSolarSense: false,
      dayNight: 'Day',
      battPercent : 0,
      isCharging: false,
      isChartShowing: false,
      chartRedrawKey: 0,
      battDevId: '',
      battData: [],
      solarData: [],
      yAxisMax: 100,
      yAxisMin: 0,
      chartStyle: {
        titleColor: 'rgba(67, 160, 71, 1.0)',
        borderColor: 'rgba(67, 160, 71, 1.0)',
        fillColor: 'rgba(67, 160, 71, 0.2)',
      },
      dlgPic: false,
    }
  },
}
</script>