<template>
  <v-container class="pa-0" style="height:calc(100% - 54px);width:100%;max-width:100%;">
    <v-row style="height:100%;" no-gutters>
      <v-col :class="{'pa-1': true, 'full-height': $vuetify.breakpoint.mdAndUp, 'mobile-map-height': !$vuetify.breakpoint.mdAndUp}"
        :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12">
        <l-map style="height:100%;z-index:0;" :bounds="bounds">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-control>
            <v-sheet class="pa-3 text-body-2" dense rounded>
                <div class="pb-2"><v-icon small color="#0000FF">mdi-circle</v-icon> 정상 동작중</div>
                <div class="pb-2"><v-icon small color="grey">mdi-circle</v-icon> 24시간 이상 미통신</div>
                <div class="pb-2"><v-icon small color="red">mdi-circle</v-icon> Keep-alive 미수신</div>
                <div class="pb-2"><v-icon small color="green">mdi-circle</v-icon> 게이트웨이 없음</div>
            </v-sheet>
          </l-control>
          <l-circle-marker 
            v-for="{groupName, groupId, latlng, radius, color, index} in markers" ref="markers" :key="index"
            :lat-lng="latlng" :radius="curGroupId == groupId ? 17 : radius" 
            :fillColor="color" :fillOpacity="0.6" :color="color"
            @click="clickMarker(groupId, groupName)">
              <l-popup>{{groupName}}</l-popup>
          </l-circle-marker> 
        </l-map>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12" class="pa-1" style="height:100%;">
        <AdminGatewayPanel :allGateways="allGateways"></AdminGatewayPanel>
        <v-sheet class="mt-2 overflow-auto" style="height:calc(100vh - 276px);">
          <MultiDataPanel :isAdmin="true" :groupId="curGroupId" :groupName="curGroupName" 
            :redrawFlag="redrawFlag" :keepalive="curKeepalive"/>
          <!-- <EdgeEyePanel :isAdmin="true" :groupId="curGroupId" :groupName="curGroupName" :redrawFlag="redrawFlag"/> -->
        </v-sheet>
        <div style="height:50px;"></div>
      </v-col>
   </v-row>
   <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
 
</template>

<script>
import axios from 'axios';
import { latLng, latLngBounds } from 'leaflet';
import { LMap, LTileLayer, LCircleMarker, LPopup, LControl } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import AdminGatewayPanel from './AdminView/AdminGatewayPanel.vue';
import MultiDataPanel from './Common/MultiDataPanel.vue';

export default {
  name: 'AdminView',
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    LPopup,
    LControl,
    MultiDataPanel,
    AdminGatewayPanel
  },
  mounted() {
    this.updateMarkers(true);

    setInterval(() => {
      this.updateMarkers(false);
      this.redrawFlag = !this.redrawFlag;
      this.curKeepalive = this.getGatewayOfGroup(this.curGroupId);
    }, 10*1000);
  },
  methods: {
    clickMarker(groupId, groupName) {
      this.curGroupId = groupId;
      this.curGroupName = groupName;
      this.redrawFlag = !this.redrawFlag;
      this.curKeepalive = this.getGatewayOfGroup(groupId);
    },
    async updateMarkers(loading) {
      this.loading = loading;

      let spotMarkers = [];
      let bounds = [];

      try {
        let allGateways = [];
        let ret = await axios({ method: 'get', url: '/api/groups',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });
        let groups = ret.data.groups;
        if(groups == null || groups.length < 1) {
          this.noGroups = true;
        } else {
          for(let i=0; i<groups.length; i++) {
            ret = await axios({ method: 'get', url: `/api/gateways/${groups[i].groupId}`,
              headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
            });
            let gateways = ret.data.gateways;
            if(gateways == null || gateways.length < 1) {
              //console.log(`not found any gateway(${groups[i].groupId})`);
            } else {
              let gwIdx = 0;
              for(let j=0; j<gateways.length; j++) {
                if(gateways[0].gwLat != undefined && gateways[0].gwLng != undefined) {
                  gwIdx = j;
                  break;
                }
              }
              if(gateways[gwIdx].gwLat != undefined && gateways[gwIdx].gwLng != undefined) {
                allGateways.push(gateways[gwIdx]);
                spotMarkers.push({
                  groupName: groups[i].groupName,
                  groupId: groups[i].groupId, 
                  latlng: latLng(gateways[gwIdx].gwLat, gateways[gwIdx].gwLng),
                  color: this.getMarkerColor(gateways[gwIdx]), radius: 11,
                  keepalive: gateways[gwIdx].gwKeepalive
                });
              } else {
                //console.log(`no gps info on gateway (${gateways[gwIdx].gwId}), ${groups[i].groupName}`);
                // this.$notify({
                //   type: 'error',
                //   group: 'foo',
                //   text: `(${groups[i].groupName})의 게이트웨이 GPS좌표값이 없어서 표시할 수 없습니다.`,
                //   duration: 60 * 1000
                // });
              }
            }
          }
          if(loading) {
            for(let i=0; i<spotMarkers.length; i++) {
              bounds.push([spotMarkers[i].latlng.lat, spotMarkers[i].latlng.lng]);
            }
            this.bounds = latLngBounds(bounds).pad(0.1);
          }
          this.markers = spotMarkers;
          if(spotMarkers.length > 0 && this.curGroupId == null) {
            this.curGroupId = spotMarkers[0].groupId;
            this.curGroupName = spotMarkers[0].groupName;
            this.curKeepalive = new Date(spotMarkers[0].keepalive).toLocaleString();
            this.redrawFlag = !this.redrawFlag;
            await this.$nextTick();
            this.$refs.markers[0].mapObject.openPopup();
          }
        }
        this.allGateways = allGateways;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    getMarkerColor(gw) {
      if(gw.gwType == '가상장치') {
        return 'green';
      } else if(gw.gwKeepalive == undefined) {
        return 'red';
      }
      let curDate = new Date();
      let recentAct = new Date(gw.gwRecentAct);
      let keepalive = new Date(gw.gwKeepalive);
      if(curDate.getTime() - keepalive.getTime() > (2*60*1000)) {
        return 'red';
      } else if(gw.gwRecentAct == undefined 
        || curDate.getTime() - recentAct.getTime() > (24*60*60*1000)) {
        return 'grey';
      } else {
        return 'blue'
      }
    },
    getGatewayOfGroup(groupId) {
      for(let i=0; i<this.allGateways.length; i++) {
        if(this.allGateways[i].gwGroupId == groupId) {
          if(this.allGateways[i].gwKeepalive != undefined) {
            return new Date(this.allGateways[i].gwKeepalive).toLocaleString();
          } else {
            return '--';
          }
        }
      }
      return '--';
    },
  },
  data() {
    return {
      loading: false,
      noGroups: false,
      markers: [],
      allGateways: [],
      bounds: null,
      curGroupId: null,
      curGroupName: '--',
      curKeepalive: '--',
      redrawFlag: true,
      isAlerting: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
}
</script>

<style>
.full-height {
  height: 100%;
}
.mobile-map-height {
  height: 70%;
}
.panel-tag-text {
  font-size:1.7em;
  font-weight: bold;
}
.panel-content-text {
  font-size:3.1em;
  font-weight: bold;
}
.txt-red {
  color: red;
}
.txt-blue {
  color: var(--v-primary-base);
}
.txt-grey {
  color: grey;
}
.extra-panel-value {
  font-size:1.3em;
}
.alert-spot-counter {
  background-color:rgb(255, 0, 0, 0.2) !important
}
.total-spot-counter {
  background-color:rgb(187, 222, 251, 0.3) !important
}
</style>

