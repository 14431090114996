<template>
  <v-card class="pa-3" elevation="1" rounded>
    <div class="mb-2 pl-2 viewer-header">
      <div class="viewer-header-title d-flex justify-space-between align-start">
        <div>
          <div>ID: {{ devId }}</div>
          <div class="viewer-header-subtitle">{{ devDesc }}</div>
        </div>
        <v-icon @click="closeViewer">mdi-close</v-icon>
      </div>
    </div>
    <v-img v-if="images.length > 0" class="mb-2" :src="getImageUrl()"></v-img>
    <div v-if="loading == false && images.length == 0" class="grey--text">저장된 이미지가 없습니다</div>
    <div class="d-flex justify-space-between align-center">
      <div><v-icon color="primary" @click="moveLeft" large :disabled="hasMore == false && curIndex == images.length-1">mdi-chevron-left</v-icon></div>
      <div>
        <v-select v-model="tsSelect" :items="images" item-value="sense_time" @change="updateImgIndex" menuProps="auto" 
         return-object dense hide-details :disabled="images.length == 0">
        <template v-slot:selection="data">
          <span class="d-flex justify-center" style="width: 100%;">
          {{ new Date(data.item.sense_time).toLocaleString() }}
          </span>
        </template>
        <template v-slot:item="data">
          <span class="d-flex justify-center" style="width: 100%;">
          {{ new Date(data.item.sense_time).toLocaleString() }}
          </span>
        </template>
        </v-select>
      </div>
      <div><v-icon color="primary" @click="moveRight" large :disabled="curIndex<=0 || this.images.length==0">mdi-chevron-right</v-icon></div>
    </div>
    <loading :active.sync="loading" :opacity=0.0 :is-full-page="false" loader="bars" />
  </v-card>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import Loading from 'vue-loading-overlay';

export default {
  name: 'EdgeEyeViewer',
  props: {
    isAdmin: Boolean,
    devId: String,
    devDesc: String,
    groupId: String,
  },
  watch: {
    devId() {
      this.initViewer();
      this.loadImages();
    }
  },
  components: {
    Loading
  },
  mounted() {
    this.initViewer();
    this.loadImages();
  },
  methods: {
    async loadImages() {
      if(this.isAdmin && this.groupId == null) { return; }

      this.loading = true;
      let curDate = this.lastDate == '' ? new Date() : new Date(this.lastDate);
      let to = curDate.toISOString();
      let from = moment(curDate).subtract(7, 'day').toDate().toISOString();
      this.lastDate = from;
      let queryStr = `nid=${this.devId}&from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/data${this.isAdmin ? '/'+this.groupId : ''}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let data = ret.data.data;
      if(data.length == 0) {
        if(this.loadingMore) {
          this.hasMore = false;
        }
        this.loadingMore = false;
        this.loading = false;
        return;
      }

      let _images = []
      for(let i=0; i<data.length; i++) {
        if(data[i].value.image != undefined && data[i].value.image != null) {
          _images.push({...data[i].value.image, sense_time: data[i].value.sense_time});
        }
      }
      this.images = [...this.images, ..._images];

      if(this.curIndex == -1) {
        this.curIndex = 0;
      } else {
        this.curIndex += 1;
      }
      this.tsSelect = this.images[this.curIndex];
      this.loading = false;
      this.loadingMore = false;
    },
    getImageUrl() {
      let fileId =  this.tsSelect.file_id;
      let grpId = this.groupId;
      return `/api/data/image/${grpId}/${fileId}`;
    },
    async moveLeft() {
      if(this.curIndex < this.images.length-1) {
        this.curIndex += 1;
        this.tsSelect = this.images[this.curIndex];
      } else if(this.curIndex == this.images.length-1) {
        this.loadingMore = true;
        this.loadImages();
      }
    },
    moveRight() {
      if(this.curIndex > 0) {
        this.curIndex -= 1;
        this.tsSelect = this.images[this.curIndex];
      }
    },
    updateImgIndex() {
      for(let i=0; i<this.images.length; i++) {
        if(this.images[i].sense_time == this.tsSelect.sense_time) {
          this.curIndex = i;
          break;
        }
      }
    },
    initViewer() {
      this.curIndex = -1;
      this.tsSelect = {};
      this.images = [];
      this.loading = false;
      this.loadingMore = false;
      this.hasMore = true;
      this.lastDate = '';
    },
    closeViewer() {
      this.$emit('close');
    }
  },
  data() {
    return {
      curIndex: -1,
      tsSelect: {},
      images: [],
      loading: false,
      loadingMore: false,
      hasMore: true,
      lastDate: ''
    };
  }
}
</script>
<style scoped>
.viewer-header {
  border-left: 8px solid #2c3e50
}
.viewer-header-title {
  font-size: 16px;
}
.viewer-header-subtitle {
  font-size: 13px;
  color: #8E8E8E;
}
.timestamp{
  font-size: 15px;
}

</style>